import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QrService {
    private _loading = new BehaviorSubject<boolean>(false);
    loading$ = this._loading.asObservable();

    constructor() {}

    setLoading(loading: boolean): void {
        this._loading.next(loading);
    }
}
